<template>
  <v-container class="mt-5">
    <v-row v-if="sportart">
      <v-col cols="12">
        <v-row>
          <v-col cols="auto" align-self="center">
            <v-btn x-large rounded icon to="/admin/sportarten" exact>
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </v-col>
          <v-col align-self="center">
            <h1 v-if="sportart.name">{{ sportart.name }} bearbeiten</h1>
            <h1 v-if="!sportart.name">Verein erstellen</h1>
          </v-col>
          <v-col cols="auto" align-self="center">
            <v-btn large rounded text color="red" @click="remove">
              <v-icon>mdi-delete</v-icon>
              Löschen
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-switch
          class="ml-auto"
          rounded
          label="Aktiv"
          v-model="sportart.active"
          @change="update"
        ></v-switch>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          filled
          rounded
          label="Name"
          v-model="sportart.name"
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
        <v-text-field
          filled
          rounded
          label="Icon"
          v-model="sportart.icon"
          @change="update"
        ></v-text-field>
      </v-col>
      <v-col cols="12" lg="6">
        <v-select
          :items="['Fontawesome', 'Material']"
          filled
          rounded
          label="Iconset"
          v-model="sportart.iconset"
          @change="update"
        ></v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { doc, updateDoc, deleteDoc } from 'firebase/firestore'
import router from '../../../router'

export default {
  name: 'Sportart',
  data() {
    return {
      sportart: '',
      vdoc: '',
    }
  },
  mounted() {
    this.setSportart()
  },
  computed: {
    ...mapGetters({
      user: 'user',
      vereine: 'vereine',
      sportarten: 'sportarten',
      db: 'db',
    }),
  },
  methods: {
    update() {
      updateDoc(this.vdoc, this.sportart)
    },
    async remove() {
      const deletion = await deleteDoc(
        doc(this.db, 'Sportarten', this.verein.id),
      )
      router.push('/admin/sportarten')
    },
    setSportart() {
      this.sportarten.all.forEach((v) => {
        if (v.id == this.$route.params.sportart) {
          this.sportart = v
        }
      })
      if (this.sportart) {
        this.vdoc = doc(this.db, 'Sportarten', this.sportart.id)
      } else {
        setTimeout(() => {
          this.setSportart()
        }, 50)
      }
    },
  },
}
</script>
